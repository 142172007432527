

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { MeasureProcessStepEmpty } from "@/store/modules/measureProcess/types";

@Component({
  components: {},
})
export default class AMeasureProcessStepEmpty extends Vue {
  @Prop({
    type: MeasureProcessStepEmpty,
  })
  readonly doc!: MeasureProcessStepEmpty;
}
